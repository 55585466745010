"use client"

import {useEffect} from "react";

export default function AffiliateTracking() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get('ref');

    if (ref) {
      window.localStorage.setItem('ref_code', ref);
      window.localStorage.setItem('ref_expire', new Date(Date.now() + 1000 * 60 * 60 * 24 * 60).toISOString());
    }
  });

  return (
    <></>
  )
}
